import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _55b7dc62 = () => interopDefault(import('../src/pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _5562cd41 = () => interopDefault(import('../src/pages/delivery-enquete/index.vue' /* webpackChunkName: "pages/delivery-enquete/index" */))
const _6ef95878 = () => interopDefault(import('../src/pages/inflow/index.vue' /* webpackChunkName: "pages/inflow/index" */))
const _53fb1fd0 = () => interopDefault(import('../src/pages/integrate-user-error.vue' /* webpackChunkName: "pages/integrate-user-error" */))
const _311569ad = () => interopDefault(import('../src/pages/line-account-renewal.vue' /* webpackChunkName: "pages/line-account-renewal" */))
const _143f0f63 = () => interopDefault(import('../src/pages/location.vue' /* webpackChunkName: "pages/location" */))
const _089f382c = () => interopDefault(import('../src/pages/mypage/index.vue' /* webpackChunkName: "pages/mypage/index" */))
const _5b31c7fa = () => interopDefault(import('../src/pages/not-authorized.vue' /* webpackChunkName: "pages/not-authorized" */))
const _5f4ef886 = () => interopDefault(import('../src/pages/out-of-area.vue' /* webpackChunkName: "pages/out-of-area" */))
const _2baa495f = () => interopDefault(import('../src/pages/pit-reservation/index.vue' /* webpackChunkName: "pages/pit-reservation/index" */))
const _35f5c945 = () => interopDefault(import('../src/pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _83a2cf50 = () => interopDefault(import('../src/pages/quotations/index.vue' /* webpackChunkName: "pages/quotations/index" */))
const _6d76bd06 = () => interopDefault(import('../src/pages/receipt/index.vue' /* webpackChunkName: "pages/receipt/index" */))
const _38e158c9 = () => interopDefault(import('../src/pages/terms.vue' /* webpackChunkName: "pages/terms" */))
const _464bb291 = () => interopDefault(import('../src/pages/tire-guarantee.vue' /* webpackChunkName: "pages/tire-guarantee" */))
const _7626dbd4 = () => interopDefault(import('../src/pages/tire-repair.vue' /* webpackChunkName: "pages/tire-repair" */))
const _327b4558 = () => interopDefault(import('../src/pages/under-construction.vue' /* webpackChunkName: "pages/under-construction" */))
const _d48363d0 = () => interopDefault(import('../src/pages/unfollow-account.vue' /* webpackChunkName: "pages/unfollow-account" */))
const _4b72711a = () => interopDefault(import('../src/pages/delivery-enquete/complete.vue' /* webpackChunkName: "pages/delivery-enquete/complete" */))
const _58ff35a0 = () => interopDefault(import('../src/pages/mypage/edit.vue' /* webpackChunkName: "pages/mypage/edit" */))
const _3ab3ff6f = () => interopDefault(import('../src/pages/mypage/purchases/index.vue' /* webpackChunkName: "pages/mypage/purchases/index" */))
const _150de880 = () => interopDefault(import('../src/pages/mypage/quotations/index.vue' /* webpackChunkName: "pages/mypage/quotations/index" */))
const _3307f5a0 = () => interopDefault(import('../src/pages/mypage/vehicles/index.vue' /* webpackChunkName: "pages/mypage/vehicles/index" */))
const _3437183c = () => interopDefault(import('../src/pages/pit-reservation/complete.vue' /* webpackChunkName: "pages/pit-reservation/complete" */))
const _24b511e6 = () => interopDefault(import('../src/pages/pit-reservation/confirm.vue' /* webpackChunkName: "pages/pit-reservation/confirm" */))
const _32455eb4 = () => interopDefault(import('../src/pages/pit-reservation/register.vue' /* webpackChunkName: "pages/pit-reservation/register" */))
const _6a34820b = () => interopDefault(import('../src/pages/pit-reservation/situation.vue' /* webpackChunkName: "pages/pit-reservation/situation" */))
const _7eb50c24 = () => interopDefault(import('../src/pages/pit-reservation/store.vue' /* webpackChunkName: "pages/pit-reservation/store" */))
const _edcba0d8 = () => interopDefault(import('../src/pages/pit-reservation/work.vue' /* webpackChunkName: "pages/pit-reservation/work" */))
const _f1cd8aba = () => interopDefault(import('../src/pages/quotations/complete.vue' /* webpackChunkName: "pages/quotations/complete" */))
const _62a46648 = () => interopDefault(import('../src/pages/quotations/details.vue' /* webpackChunkName: "pages/quotations/details" */))
const _bf293944 = () => interopDefault(import('../src/pages/receipt/complete.vue' /* webpackChunkName: "pages/receipt/complete" */))
const _e3eb80aa = () => interopDefault(import('../src/pages/receipt/confirm.vue' /* webpackChunkName: "pages/receipt/confirm" */))
const _742dab58 = () => interopDefault(import('../src/pages/receipt/enquete.vue' /* webpackChunkName: "pages/receipt/enquete" */))
const _15774409 = () => interopDefault(import('../src/pages/receipt/qr/index.vue' /* webpackChunkName: "pages/receipt/qr/index" */))
const _53119bc8 = () => interopDefault(import('../src/pages/receipt/register.vue' /* webpackChunkName: "pages/receipt/register" */))
const _6561b586 = () => interopDefault(import('../src/pages/vehicles/registration/index.vue' /* webpackChunkName: "pages/vehicles/registration/index" */))
const _7b2ba3a4 = () => interopDefault(import('../src/pages/mypage/pit-reservation/complete.vue' /* webpackChunkName: "pages/mypage/pit-reservation/complete" */))
const _29bddea5 = () => interopDefault(import('../src/pages/mypage/pit-reservation/confirm.vue' /* webpackChunkName: "pages/mypage/pit-reservation/confirm" */))
const _01d163a3 = () => interopDefault(import('../src/pages/mypage/pit-reservation/situation.vue' /* webpackChunkName: "pages/mypage/pit-reservation/situation" */))
const _0004ffaf = () => interopDefault(import('../src/pages/receipt/qr/read.vue' /* webpackChunkName: "pages/receipt/qr/read" */))
const _876c3296 = () => interopDefault(import('../src/pages/vehicles/registration/complete.vue' /* webpackChunkName: "pages/vehicles/registration/complete" */))
const _d19b1518 = () => interopDefault(import('../src/pages/vehicles/registration/confirm.vue' /* webpackChunkName: "pages/vehicles/registration/confirm" */))
const _6b632ac6 = () => interopDefault(import('../src/pages/vehicles/registration/qr.vue' /* webpackChunkName: "pages/vehicles/registration/qr" */))
const _63960c9f = () => interopDefault(import('../src/pages/vehicles/registration/select-type.vue' /* webpackChunkName: "pages/vehicles/registration/select-type" */))
const _e238cad8 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _02950b1f = () => interopDefault(import('../src/pages/mypage/pit-reservation/_id.vue' /* webpackChunkName: "pages/mypage/pit-reservation/_id" */))
const _18c91197 = () => interopDefault(import('../src/pages/mypage/purchases/_id.vue' /* webpackChunkName: "pages/mypage/purchases/_id" */))
const _3f56fe28 = () => interopDefault(import('../src/pages/mypage/quotations/_id.vue' /* webpackChunkName: "pages/mypage/quotations/_id" */))
const _05523ff0 = () => interopDefault(import('../src/pages/mypage/vehicles/_id.vue' /* webpackChunkName: "pages/mypage/vehicles/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/contact/",
    component: _55b7dc62,
    pathToRegexpOptions: {"strict":true},
    name: "contact"
  }, {
    path: "/delivery-enquete/",
    component: _5562cd41,
    pathToRegexpOptions: {"strict":true},
    name: "delivery-enquete"
  }, {
    path: "/inflow/",
    component: _6ef95878,
    pathToRegexpOptions: {"strict":true},
    name: "inflow"
  }, {
    path: "/integrate-user-error/",
    component: _53fb1fd0,
    pathToRegexpOptions: {"strict":true},
    name: "integrate-user-error"
  }, {
    path: "/line-account-renewal/",
    component: _311569ad,
    pathToRegexpOptions: {"strict":true},
    name: "line-account-renewal"
  }, {
    path: "/location/",
    component: _143f0f63,
    pathToRegexpOptions: {"strict":true},
    name: "location"
  }, {
    path: "/mypage/",
    component: _089f382c,
    pathToRegexpOptions: {"strict":true},
    name: "mypage"
  }, {
    path: "/not-authorized/",
    component: _5b31c7fa,
    pathToRegexpOptions: {"strict":true},
    name: "not-authorized"
  }, {
    path: "/out-of-area/",
    component: _5f4ef886,
    pathToRegexpOptions: {"strict":true},
    name: "out-of-area"
  }, {
    path: "/pit-reservation/",
    component: _2baa495f,
    pathToRegexpOptions: {"strict":true},
    name: "pit-reservation"
  }, {
    path: "/privacy-policy/",
    component: _35f5c945,
    pathToRegexpOptions: {"strict":true},
    name: "privacy-policy"
  }, {
    path: "/quotations/",
    component: _83a2cf50,
    pathToRegexpOptions: {"strict":true},
    name: "quotations"
  }, {
    path: "/receipt/",
    component: _6d76bd06,
    pathToRegexpOptions: {"strict":true},
    name: "receipt"
  }, {
    path: "/terms/",
    component: _38e158c9,
    pathToRegexpOptions: {"strict":true},
    name: "terms"
  }, {
    path: "/tire-guarantee/",
    component: _464bb291,
    pathToRegexpOptions: {"strict":true},
    name: "tire-guarantee"
  }, {
    path: "/tire-repair/",
    component: _7626dbd4,
    pathToRegexpOptions: {"strict":true},
    name: "tire-repair"
  }, {
    path: "/under-construction/",
    component: _327b4558,
    pathToRegexpOptions: {"strict":true},
    name: "under-construction"
  }, {
    path: "/unfollow-account/",
    component: _d48363d0,
    pathToRegexpOptions: {"strict":true},
    name: "unfollow-account"
  }, {
    path: "/delivery-enquete/complete/",
    component: _4b72711a,
    pathToRegexpOptions: {"strict":true},
    name: "delivery-enquete-complete"
  }, {
    path: "/mypage/edit/",
    component: _58ff35a0,
    pathToRegexpOptions: {"strict":true},
    name: "mypage-edit"
  }, {
    path: "/mypage/purchases/",
    component: _3ab3ff6f,
    pathToRegexpOptions: {"strict":true},
    name: "mypage-purchases"
  }, {
    path: "/mypage/quotations/",
    component: _150de880,
    pathToRegexpOptions: {"strict":true},
    name: "mypage-quotations"
  }, {
    path: "/mypage/vehicles/",
    component: _3307f5a0,
    pathToRegexpOptions: {"strict":true},
    name: "mypage-vehicles"
  }, {
    path: "/pit-reservation/complete/",
    component: _3437183c,
    pathToRegexpOptions: {"strict":true},
    name: "pit-reservation-complete"
  }, {
    path: "/pit-reservation/confirm/",
    component: _24b511e6,
    pathToRegexpOptions: {"strict":true},
    name: "pit-reservation-confirm"
  }, {
    path: "/pit-reservation/register/",
    component: _32455eb4,
    pathToRegexpOptions: {"strict":true},
    name: "pit-reservation-register"
  }, {
    path: "/pit-reservation/situation/",
    component: _6a34820b,
    pathToRegexpOptions: {"strict":true},
    name: "pit-reservation-situation"
  }, {
    path: "/pit-reservation/store/",
    component: _7eb50c24,
    pathToRegexpOptions: {"strict":true},
    name: "pit-reservation-store"
  }, {
    path: "/pit-reservation/work/",
    component: _edcba0d8,
    pathToRegexpOptions: {"strict":true},
    name: "pit-reservation-work"
  }, {
    path: "/quotations/complete/",
    component: _f1cd8aba,
    pathToRegexpOptions: {"strict":true},
    name: "quotations-complete"
  }, {
    path: "/quotations/details/",
    component: _62a46648,
    pathToRegexpOptions: {"strict":true},
    name: "quotations-details"
  }, {
    path: "/receipt/complete/",
    component: _bf293944,
    pathToRegexpOptions: {"strict":true},
    name: "receipt-complete"
  }, {
    path: "/receipt/confirm/",
    component: _e3eb80aa,
    pathToRegexpOptions: {"strict":true},
    name: "receipt-confirm"
  }, {
    path: "/receipt/enquete/",
    component: _742dab58,
    pathToRegexpOptions: {"strict":true},
    name: "receipt-enquete"
  }, {
    path: "/receipt/qr/",
    component: _15774409,
    pathToRegexpOptions: {"strict":true},
    name: "receipt-qr"
  }, {
    path: "/receipt/register/",
    component: _53119bc8,
    pathToRegexpOptions: {"strict":true},
    name: "receipt-register"
  }, {
    path: "/vehicles/registration/",
    component: _6561b586,
    pathToRegexpOptions: {"strict":true},
    name: "vehicles-registration"
  }, {
    path: "/mypage/pit-reservation/complete/",
    component: _7b2ba3a4,
    pathToRegexpOptions: {"strict":true},
    name: "mypage-pit-reservation-complete"
  }, {
    path: "/mypage/pit-reservation/confirm/",
    component: _29bddea5,
    pathToRegexpOptions: {"strict":true},
    name: "mypage-pit-reservation-confirm"
  }, {
    path: "/mypage/pit-reservation/situation/",
    component: _01d163a3,
    pathToRegexpOptions: {"strict":true},
    name: "mypage-pit-reservation-situation"
  }, {
    path: "/receipt/qr/read/",
    component: _0004ffaf,
    pathToRegexpOptions: {"strict":true},
    name: "receipt-qr-read"
  }, {
    path: "/vehicles/registration/complete/",
    component: _876c3296,
    pathToRegexpOptions: {"strict":true},
    name: "vehicles-registration-complete"
  }, {
    path: "/vehicles/registration/confirm/",
    component: _d19b1518,
    pathToRegexpOptions: {"strict":true},
    name: "vehicles-registration-confirm"
  }, {
    path: "/vehicles/registration/qr/",
    component: _6b632ac6,
    pathToRegexpOptions: {"strict":true},
    name: "vehicles-registration-qr"
  }, {
    path: "/vehicles/registration/select-type/",
    component: _63960c9f,
    pathToRegexpOptions: {"strict":true},
    name: "vehicles-registration-select-type"
  }, {
    path: "/",
    component: _e238cad8,
    pathToRegexpOptions: {"strict":true},
    name: "index"
  }, {
    path: "/mypage/pit-reservation/:id?/",
    component: _02950b1f,
    pathToRegexpOptions: {"strict":true},
    name: "mypage-pit-reservation-id"
  }, {
    path: "/mypage/purchases/:id/",
    component: _18c91197,
    pathToRegexpOptions: {"strict":true},
    name: "mypage-purchases-id"
  }, {
    path: "/mypage/quotations/:id/",
    component: _3f56fe28,
    pathToRegexpOptions: {"strict":true},
    name: "mypage-quotations-id"
  }, {
    path: "/mypage/vehicles/:id/",
    component: _05523ff0,
    pathToRegexpOptions: {"strict":true},
    name: "mypage-vehicles-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
